<template>
    <div class="root">
        <div class="con">
            <v-text-field v-model="number" @keyup.enter="call" solo flat hide-details class="input"></v-text-field>
            <div class="text-center mt-2">
                <v-btn @click="call" color="primary">호출</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            number: null
        }
    },
    methods: {
        call() {
            this.speak(this.num2han(this.number) + "번 고객님")
            this.speak("주문하신 음료 나왔습니다 ")
            this.number = null
        },
        speak(text) {

            var utterThis = new SpeechSynthesisUtterance(text);
            utterThis.lang = "ko-KR";
            // utterThis.voice = "유나"
            utterThis.pitch = 1;
            utterThis.rate = 0.9;  //속도
            window.speechSynthesis.speak(utterThis);
        },
        num2han(num) {
            if (num) {
                num = parseInt((num + "").replace(/[^0-9]/g, ""), 10) + ""; // 숫자/문자/돈 을 숫자만 있는 문자열로 변환
                if (num == "0") return "영";
                var number = [
                    "영",
                    "일",
                    "이",
                    "삼",
                    "사",
                    "오",
                    "육",
                    "칠",
                    "팔",
                    "구",
                ];
                var unit = [
                    "",
                    "만",
                    "억",
                    "조",
                    "경",
                    "해",
                    "자",
                    "양",
                    "구",
                    "간",
                    "정",
                ];
                var smallUnit = ["천", "백", "십", ""];
                var result = []; //변환된 값을 저장할 배열
                var unitCnt = Math.ceil(num.length / 4); //단위 갯수. 숫자 10000은 일단위와 만단위 2개이다.
                num = num.padStart(unitCnt * 4, "0"); //4자리 값이 되도록 0을 채운다
                var regexp = /[\w\W]{4}/g; //4자리 단위로 숫자 분리
                var array = num.match(regexp);
                //낮은 자릿수에서 높은 자릿수 순으로 값을 만든다(그래야 자릿수 계산이 편하다)
                for (var i = array.length - 1, unitCnt = 0; i >= 0; i--, unitCnt++) {
                    var hanValue = _makeHan(array[i]); //한글로 변환된 숫자
                    if (hanValue == "")
                        //값이 없을땐 해당 단위의 값이 모두 0이란 뜻.
                        continue;
                    result.unshift(hanValue + unit[unitCnt]); //unshift는 항상 배열의 앞에 넣는다.
                }
                //여기로 들어오는 값은 무조건 네자리이다. 1234 -> 일천이백삼십사
                function _makeHan(text) {
                    var str = "";

                    for (var i = 0; i < text.length; i++) {
                        var num = text[i];
                        if (num == "0") {
                            // 0은 읽지 않는다
                            continue;
                        }
                        //마지막자리는 무조건 1읽음
                        if (i == 3) {
                            str += number[num] + smallUnit[i];
                        }
                        //그외에자리수는 1은 천,백,십 단위로만읽음
                        else if (num === "1") {

                            str += smallUnit[i];
                            continue;
                        }
                        else {
                            str += number[num] + smallUnit[i];
                        }



                    }
                    return str;
                }
                return result.join("");
            }
        },
    }
}
</script>

<style scoped lang="less">
.root {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
</style>