<template>
    <div class="root">
        <div class="section-box">
            <v-tabs v-model="tab">
                <v-tab>메뉴</v-tab>
                <v-tab>옵션</v-tab>
            </v-tabs>
            <div class="category-list mt-2 pt-2" v-if="tab == 0">
                <div class="text-right">
                    <v-btn @click="addCategory" outlined color="primary">카테고리추가</v-btn>
                </div>
                <draggable v-model="categoryList" handle=".handle" @change="reorderCategory(categoryList)">
                    <div class="category-item" v-for="category in categoryList" :key="category.id">
                        <v-layout align-center>
                            <v-btn icon @click="selectCategory(category)">
                                <v-icon
                                    v-if="selectedCategory && selectedCategory.id == category.id">mdi-chevron-up</v-icon>
                                <v-icon v-else>mdi-chevron-down</v-icon>
                            </v-btn>
                            <div class="ml-1 mr-1">
                                <v-icon class="handle">mdi-menu</v-icon>
                            </div>

                            <v-flex>
                                <div class="catgegory-title">{{ category.title }}</div>

                            </v-flex>
                            <div class="pl-2 pr-2" v-if="category.printerType != 0">
                                프린터 : {{ category.printerType }}
                            </div>
                            <div class="pl-2 pr-2" v-if="category.visibleStartTime && category.visibleEndTime">
                                노출:{{ category.visibleStartTime | time("HH:mm") }}~{{ category.visibleEndTime |
                                    time("HH:mm") }} {{ getVisibleDay(category) }}
                            </div>
                            <div class="pl-2 pr-2" v-if="category.hiddenStartTime && category.hiddenEndTime">
                                숨김:{{ category.hiddenStartTime | time("HH:mm") }}~{{ category.hiddenEndTime |
                                    time("HH:mm") }}{{ getHiddenDay(category) }}
                            </div>
                            <v-btn icon color="primary" @click="openItemDialog(category)">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn icon @click="openCategoryDialog(category)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn icon color="error">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-layout>

                        <div class="item-list" v-if="selectedCategory && selectedCategory.id == category.id">
                            <draggable v-model="category.childs" handle=".handle" @change="reorderItem(category.childs)">
                                <div class="item" v-for="(item, key) in category.childs" :key="item.key">
                                    <v-layout align-center>
                                        <v-icon class="handle">mdi-menu</v-icon>
                                        <hh-input v-model="item.title"></hh-input>
                                        <div class="mr-3">{{ item.price | number }}원</div>
                                        <v-btn icon color="primary" @click="openItemDialog(category, item)">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn icon color="error" @click="removeCategoryItem(item)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-layout>
                                </div>
                            </draggable>
                        </div>
                    </div>
                </draggable>
            </div>
            <div v-if="tab == 1">
                <div class="text-right">
                    <v-btn @click="openOptionDialog()" outlined color="primary">옵션추가</v-btn>
                </div>
                <div class="option-list">
                    <draggable v-model="optionList" handle=".handle" @change="reorderOption(optionList)">
                        <div class="option-item" v-for="(option, key) in optionList" :key="key">
                            <v-layout align-center>
                                <v-icon class="handle">mdi-menu</v-icon>
                                <div>
                                    {{ option.name }}
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="openOptionDialog(option)">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </v-layout>

                        </div>
                    </draggable>

                </div>
            </div>
        </div>
        <!-- 카테고리 다이얼로그 -->
        <v-dialog content-class="category-dialog" v-model="categoryDialog.visible" width="600px">
            <v-card class="pa-4" v-if="categoryDialog.item && categoryDialog.visible">
                <div class="input-header">카테고리 이름</div>
                <hh-input class="input" v-model="categoryDialog.item.title"></hh-input>
                <div class="input-header">카테고리 노출시간</div>
                <v-layout align-center class="input">
                    <div>
                        <time-picker2 v-model="categoryDialog.item.visibleStartTime"></time-picker2>
                    </div>
                    <div>~</div>
                    <div>
                        <time-picker2 v-model="categoryDialog.item.visibleEndTime"></time-picker2>
                    </div>
                    <v-btn color="primary" text @click="clearVisibleTime">초기화</v-btn>
                </v-layout>
                <v-layout>
                    <v-checkbox v-model="categoryDialog.item.visibleSun" class="mr-2" label="일" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="categoryDialog.item.visibleMon" class="mr-2" label="월" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="categoryDialog.item.visibleTue" class="mr-2" label="화" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="categoryDialog.item.visibleWed" class="mr-2" label="수" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="categoryDialog.item.visibleThu" class="mr-2" label="목" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="categoryDialog.item.visibleFri" class="mr-2" label="금" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="categoryDialog.item.visibleSat" class="mr-2" label="토" solo flat
                        hide-details></v-checkbox>
                </v-layout>

                <div class="input-header">카테고리 숨김시간</div>
                <v-layout align-center class="input">
                    <div>
                        <time-picker2 v-model="categoryDialog.item.hiddenStartTime"></time-picker2>
                    </div>
                    <div>~</div>
                    <div>
                        <time-picker2 v-model="categoryDialog.item.hiddenEndTime"></time-picker2>
                    </div>
                    <v-btn color="primary" text @click="clearHiddenTime">초기화</v-btn>
                </v-layout>
                <v-layout>
                    <v-checkbox v-model="categoryDialog.item.hiddenSun" class="mr-2" label="일" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="categoryDialog.item.hiddenMon" class="mr-2" label="월" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="categoryDialog.item.hiddenTue" class="mr-2" label="화" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="categoryDialog.item.hiddenWed" class="mr-2" label="수" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="categoryDialog.item.hiddenThu" class="mr-2" label="목" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="categoryDialog.item.hiddenFri" class="mr-2" label="금" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="categoryDialog.item.hiddenSat" class="mr-2" label="토" solo flat
                        hide-details></v-checkbox>
                </v-layout>
                <div class="input-header">주문프린터</div>
                <v-select class="input" solo flat hide-details v-model="categoryDialog.item.printerType"
                    :items="printerType"></v-select>

                <div class="input-header">POS에만 보이는 카테고리</div>
                <v-checkbox class="mt-2" v-model="categoryDialog.item.isVisibleOnlyPos" hide-details></v-checkbox>

                <div class="text-center mt-2">
                    <v-btn @click="categorySubmit(categoryDialog.item)">수정</v-btn>
                </div>
            </v-card>
        </v-dialog>
        <!-- 메뉴 다이어로그 -->
        <v-dialog v-model="itemDialog.visible" v-if="itemDialog.visible" width="600px">
            <v-card class="pa-4" v-if="itemDialog.item">
                <div class="text-center">
                    <v-responsive :aspect-ratio="16 / 9">
                        <image-box class="image-container" @change="changeItemImage"
                            :prefill="getPrefill(itemDialog.item)"></image-box>
                    </v-responsive>

                </div>
                <div class="input-header">제목</div>
                <hh-input class="input" v-model="itemDialog.item.title" placeholder="제목"></hh-input>

                <div v-if="itemDialog.g18nExtends">
                    <hh-input class="input" v-model="itemDialog.item['title:en']" placeholder="영문"></hh-input>
                    <hh-input class="input" v-model="itemDialog.item['title:jp']" placeholder="일본"></hh-input>
                    <hh-input class="input" v-model="itemDialog.item['title:cn']" placeholder="중문"></hh-input>
                </div>
                <div class="text-center">
                    <v-btn text @click="itemDialog.g18nExtends = !itemDialog.g18nExtends">
                        <span v-if="itemDialog.g18nExtends">다국어보기</span>
                        <span v-if="!itemDialog.g18nExtends">다국어숨기기</span>
                    </v-btn>
                </div>



                <v-switch v-model="itemDialog.item.isPricePerWeight" label="무게당 가격 상품" hide-details></v-switch>
                <div class="input-header">가격</div>
                <v-layout align-center class="input">
                    <v-flex>
                        <hh-money v-model="itemDialog.item.price" suffix="원"></hh-money>
                    </v-flex>

                    <div v-if="itemDialog.item.isPricePerWeight">/</div>
                    <v-flex v-if="itemDialog.item.isPricePerWeight">
                        <hh-money v-model="itemDialog.item.weightBase"></hh-money>
                    </v-flex>
                    <v-select v-model="itemDialog.item.weightUnit" v-if="itemDialog.item.isPricePerWeight"
                        :items="weightUnits" solo flat hide-details></v-select>
                </v-layout>

                <div class="input-header">할인금액</div>
                <hh-money class="input" v-model="itemDialog.item.discountPrice" suffix="원"></hh-money>

                <div class="input-header">이벤트벳지명</div>
                <hh-input class="input" v-model="itemDialog.item.eventBadge"></hh-input>


                <div class="input-header">설명</div>
                <v-textarea class="input" solo flat hide-details v-model="itemDialog.item.description"
                    placeholder="설명"></v-textarea>

                <div v-if="itemDialog.g18nExtends">
                    <v-textarea class="input" solo flat hide-details v-model="itemDialog.item['description:en']"
                        placeholder="설명"></v-textarea>
                    <v-textarea class="input" solo flat hide-details v-model="itemDialog.item['description:jp']"
                        placeholder="설명"></v-textarea>
                    <v-textarea class="input" solo flat hide-details v-model="itemDialog.item['description:cn']"
                        placeholder="설명"></v-textarea>

                </div>
                <div class="text-center">
                    <v-btn text @click="itemDialog.g18nExtends = !itemDialog.g18nExtends">
                        <span v-if="itemDialog.g18nExtends">다국어보기</span>
                        <span v-if="!itemDialog.g18nExtends">다국어숨기기</span>
                    </v-btn>
                </div>

                <div class="input-header">최소주문수량</div>
                <hh-money class="input" v-model="itemDialog.item.minimumCount" suffix="개"></hh-money>


                <v-checkbox hide-details v-model="itemDialog.item.soldout" label="품절"></v-checkbox>
                <v-checkbox hide-details v-model="itemDialog.item.recommended" label="추천"></v-checkbox>
                <v-checkbox hide-details v-model="itemDialog.item.isVisibleOnlyPos" label="Pos에서만 보이는메뉴"></v-checkbox>
                <v-checkbox hide-details v-model="itemDialog.item.isAddNoti" label="추가주문시 표시"></v-checkbox>
                <v-checkbox hide-details v-model="itemDialog.item.forceOpenDetailDialog" label="무조건 팝업 띄우기"></v-checkbox>
                <v-checkbox hide-details v-model="itemDialog.item.isCheckID" label="신분증 확인메뉴"></v-checkbox>
                <v-checkbox hide-details v-model="itemDialog.item.isSeparatePrint" label="주문지 별도출력"></v-checkbox>

                <div class="input-header">메뉴 노출시간</div>
                <v-layout align-center class="input">
                    <div>
                        <time-picker2 v-model="itemDialog.item.visibleStartTime"></time-picker2>
                    </div>
                    <div>~</div>
                    <div>
                        <time-picker2 v-model="itemDialog.item.visibleEndTime"></time-picker2>
                    </div>
                    <v-btn color="primary" text @click="clearMenuVisibleTime">초기화</v-btn>
                </v-layout>
                <v-layout>
                    <v-checkbox v-model="itemDialog.item.visibleSun" class="mr-2" label="일" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="itemDialog.item.visibleMon" class="mr-2" label="월" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="itemDialog.item.visibleTue" class="mr-2" label="화" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="itemDialog.item.visibleWed" class="mr-2" label="수" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="itemDialog.item.visibleThu" class="mr-2" label="목" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="itemDialog.item.visibleFri" class="mr-2" label="금" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="itemDialog.item.visibleSat" class="mr-2" label="토" solo flat
                        hide-details></v-checkbox>
                </v-layout>

                <div class="input-header">메뉴 숨김시간</div>
                <v-layout align-center class="input">
                    <div>
                        <time-picker2 v-model="itemDialog.item.hiddenStartTime"></time-picker2>
                    </div>
                    <div>~</div>
                    <div>
                        <time-picker2 v-model="itemDialog.item.hiddenEndTime"></time-picker2>
                    </div>
                    <v-btn color="primary" text @click="clearMenuHiddenTime">초기화</v-btn>
                </v-layout>
                <v-layout>
                    <v-checkbox v-model="itemDialog.item.hiddenSun" class="mr-2" label="일" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="itemDialog.item.hiddenMon" class="mr-2" label="월" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="itemDialog.item.hiddenTue" class="mr-2" label="화" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="itemDialog.item.hiddenWed" class="mr-2" label="수" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="itemDialog.item.hiddenThu" class="mr-2" label="목" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="itemDialog.item.hiddenFri" class="mr-2" label="금" solo flat
                        hide-details></v-checkbox>
                    <v-checkbox v-model="itemDialog.item.hiddenSat" class="mr-2" label="토" solo flat
                        hide-details></v-checkbox>
                </v-layout>
                <div class="input-header">주문프린터</div>
                <v-select class="input" solo flat hide-details v-model="itemDialog.item.printerType"
                    :items="printerType"></v-select>


                <div class="option-container">
                    <div class="input-header">옵션</div>
                    <v-layout align-center>


                        <v-select v-model="itemDialog.selectedOption" :items="optionList" item-text="name" item-value="id"
                            solo flat hide-details return-object></v-select>
                        <v-btn color="primary" text @click="itemAddOption(itemDialog.selectedOption)">추가</v-btn>
                    </v-layout>

                    <div class="option-list">
                        <draggable v-model="itemDialog.item.options" handle=".handle">
                            <div class="option-item" v-for="(option, key) in itemDialog.item.options"
                                :class="{ deleted: option.deleted }">
                                <v-layout align-center>
                                    <v-icon class="handle">mdi-menu</v-icon>
                                    <div>
                                        {{ option.name }}
                                    </div>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="itemRemoveOption(itemDialog.item.options, option)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-layout>

                            </div>
                        </draggable>

                    </div>
                </div>


                <div class="text-center mt-2">
                    <v-btn v-if="itemDialog.item.id" color="primary" depressed @click="itemModify">수정</v-btn>
                    <v-btn v-else color="primary" depressed @click="itemSubmit">추가</v-btn>
                </div>
            </v-card>
        </v-dialog>
        <!-- 옵션 다이어로그 -->
        <v-dialog v-model="optionDialog.visible" width="600px">
            <v-card class="pa-4" v-if="optionDialog.item">
                <div class="input-header">이용자에게 보이는 이름</div>
                <hh-input class="input" v-model="optionDialog.item.displayName"></hh-input>
                <div class="input-header">시스템상의 이름</div>
                <hh-input class="input" v-model="optionDialog.item.name"></hh-input>
                <div class="input-header">옵션타입</div>
                <v-select v-model="optionDialog.item.type" :items="optionTypes" solo flat hide-details
                    class="input"></v-select>

                <div class="input-header">필수선택</div>
                <v-checkbox v-model="optionDialog.item.required"></v-checkbox>
                <v-layout align-center claass="mt-4 input-header">
                    <div>항목</div>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="addOptionItem(optionDialog.item.items)">추가</v-btn>
                </v-layout>
                <div class="option-list">
                    <draggable v-model="optionDialog.item.items" handle=".handle">
                        <div v-for="(optionItem, key) in optionDialog.item.items" :key="key" class="option-item"
                            :class="{ deleted: optionItem.deleted }">
                            <v-layout>
                                <v-icon class="handle">mdi-menu</v-icon>
                                <hh-input class="ml-2" placeholder="옵션이름" v-model="optionItem.name"></hh-input>
                                <hh-money placeholder="옵션가격" v-model="optionItem.price"></hh-money>
                                <v-btn color="red" dark text
                                    @click="removeItem(optionItem, optionDialog.item.items)">삭제</v-btn>
                            </v-layout>
                        </div>

                    </draggable>
                </div>
                <div class="text-center">
                    <v-btn color="primary" @click="optionSubmit">추가</v-btn>
                </div>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import _ from "underscore";
import printerType from "@/data/printerType"
import TimePicker2 from "@/components/TimePicker2"

export default {
    components: {
        TimePicker2,
        draggable,
    },
    data() {
        return {
            weightUnits: [
                { text: "g", value: 100 },
                { text: "kg", value: 1000 },
                { text: "ml", value: 200 },
                { text: "L", value: 2000 },
            ],
            tab: 0,
            printerType,
            categoryList: [],
            optionList: [],
            selectedCategory: null,
            categoryDialog: {
                visible: false,
                item: null
            },
            itemDialog: {
                visible: false,
                item: null,
                g18nExtends: false,
                selectedOption: null
            },
            optionDialog: {
                visible: false,
                item: null
            },
            optionTypes: [
                { text: "단일선택", "value": 1 },
                { text: "다중선택", "value": 2 }
            ]
        }

    },
    mounted() {
        this.getCategoryList()
        this.getOptionList()
    },
    methods: {
        itemRemoveOption(options, option) {
            if (!confirm("정말 삭제하시겠습니까?")) return;

            this.$axios.post("/item/option/remove", {
                itemId: this.itemDialog.item.id,
                optionId: option.id
            })
                .then((result) => {
                    if (result.data.res) {
                        options.splice(options.indexOf(option), 1)
                    }
                })
            // if (option.id) {
            //     option.deleted = true;
            // }
            // else {
            //     options.splice(options.indexOf(option), 1)
            // }
            // option.deleted = true;
        },
        selectCategory(category) {
            this.selectedCategory = category
        },
        removeItem(item, list) {
            if (item.id) {
                item.deleted = true
            }
            else {
                list.splice(list.indexOf(item), 1)
            }
        },
        getHiddenDay(category) {
            var days = [];
            if (category.hiddenSun) {
                days.push("일")
            }
            if (category.hiddenMon) {
                days.push("월")
            }
            if (category.hiddenTue) {
                days.push("화")
            }
            if (category.hiddenWed) {
                days.push("수")
            }
            if (category.hiddenThu) {
                days.push("목")
            }
            if (category.hiddenFri) {
                days.push("금")
            }
            if (category.hiddenSat) {
                days.push("토")
            }
            return days.join(",")
        },
        getVisibleDay(category) {

            var days = [];
            if (category.visibleSun) {
                days.push("일")
            }
            if (category.visibleMon) {
                days.push("월")
            }
            if (category.visibleTue) {
                days.push("화")
            }
            if (category.visibleWed) {
                days.push("수")
            }
            if (category.visibleThu) {
                days.push("목")
            }
            if (category.visibleFri) {
                days.push("금")
            }
            if (category.visibleSat) {
                days.push("토")
            }
            return days.join(",")

        },
        clearVisibleTime() {
            this.categoryDialog.item.visibleStartTime = null;
            this.categoryDialog.item.visibleEndTime = null;
        },
        clearHiddenTime() {
            this.categoryDialog.item.hiddenStartTime = null;
            this.categoryDialog.item.hiddenEndTime = null;
        },
        clearMenuVisibleTime() {
            this.itemDialog.item.visibleStartTime = null;
            this.itemDialog.item.visibleEndTime = null;
        },
        clearMenuHiddenTime() {
            this.itemDialog.item.hiddenStartTime = null;
            this.itemDialog.item.hiddenEndTime = null;
        },

        openCategoryDialog(item) {
            this.categoryDialog.item = _.clone(item) || {
                title: "",
                deleted: false,
                visibleStartTime: null,
                visibleEndTime: null,
                hiddenStartTime: null,
                hiddenEndTime: null,
                printerType: 0,
                description: "",
            };
            this.categoryDialog.visible = true;

        },
        itemAddOption(option) {
            if (!option) {
                return;
            }
            if (!this.itemDialog.item.options) {
                this.itemDialog.item.options = [];
            }
            this.itemDialog.item.options.push(option)
        },
        getOptionList() {
            this.$axios.post("/option/list", {
                storeId: this.$route.params.storeId
            })
                .then(result => {
                    if (result.data.res) {
                        this.optionList = result.data.list;
                    }
                })
        },
        optionSubmit() {
            this.$axios.post("/option/submit", this.optionDialog.item)
                .then((result) => {
                    if (result.data.res) {
                        window.alert("추가되었습니다")
                        this.optionDialog.visible = false;
                        this.getOptionList()
                    }
                })
        },
        addOptionItem(items) {
            items.push({
                name: "",
                price: 0

            })
        },
        categorySubmit(category) {
            if (!confirm("정말 수정하시겠습니까?")) {
                return;
            }
            this.$axios.post("/category/modify", category)
                .then((result) => {
                    if (result.data.res) {
                        window.alert("수정되었습니다")
                        this.categoryDialog.visible = false;
                        this.getCategoryList();
                    }

                });

        },
        addCategory() {
            var title = window.prompt("카테고리 제목을 입력해주세요");
            if (title) {
                this.$axios
                    .post("/category/add", {
                        title: title,
                        storeId: this.$route.params.storeId,
                    })
                    .then((result) => {
                        this.getCategoryList();
                    });
            }
        },
        getCategoryList() {
            this.$axios
                .post("/category/list", {
                    storeId: this.$route.params.storeId,
                })
                .then((result) => {
                    this.categoryList = result.data.list;
                });
        },
        reorderCategory(list) {

            var reorderList = _.map(list, (item, key) => {

                return {
                    id: item.id,
                    order: key
                };
            });
            this.$axios.post("/category/reorder", {
                list: reorderList,
            });
        },
        reorderOption(list) {
            var reorderList = _.map(list, (item, key) => {

                return {
                    id: item.id,
                    order: key
                };
            });
            this.$axios.post("/option/reorder", {
                list: reorderList,
            });
        },
        reorderItem(list) {

            var reorderList = _.map(list, (item, key) => {

                return {
                    id: item.id,
                    order: key
                };
            });

            this.$axios.post("/category/item/reorder", {
                list: reorderList,
            });
        },

        changeImage(image) {
            this.file = image;
        },
        getPrefill(item) {
            console.log(item);
            if (item && item.image && item.image.imageUrl) {
                return item.image.imageUrl;
            }
        },
        removeCategoryItem(item) {
            if (!confirm("정말 삭제하시겠습니까?")) return;
            this.$axios
                .post("/category/item/remove", {
                    id: item.id,
                })
                .then((result) => {
                    if (result.data.res) {
                        this.getCategoryList();
                    }
                });
        },
        changeItemImage(file) {
            this.itemDialog.item.file = file;
        },
        async itemModify() {
            if (this.itemDialog.item.file) {
                var formData = new FormData();
                formData.append("file", this.itemDialog.item.file);
                var { data } = await this.$axios.post("/file/upload", formData);
                this.itemDialog.item.imageId = data.file.id;
            }
            this.$axios
                .post("/category/item/modify", this.itemDialog.item)
                .then((result) => {
                    this.itemDialog.visible = false;
                    this.itemDialog.item = null;
                    this.getCategoryList();
                });
        },
        async itemSubmit() {
            if (this.itemDialog.item.file) {
                var formData = new FormData();
                formData.append("file", this.itemDialog.item.file);
                var { data } = await this.$axios.post("/file/upload", formData);
                this.itemDialog.item.imageId = data.file.id;
            }
            this.$axios
                .post("/category/item/add", this.itemDialog.item)
                .then((result) => {
                    this.itemDialog.visible = false;
                    this.itemDialog.item = null;
                    this.getCategoryList();
                });
        },
        openItemDialog(category, item) {
            this.itemDialog.item = item || {
                file: null,
                MenuCategoryId: category.id,
                title: "",
                description: "",
                price: null,
                options: []
            };
            this.itemDialog.visible = true;
            this.itemDialog.selectedOption = null
        },
        openOptionDialog(option) {
            this.optionDialog.item = option || {
                name: "",
                displayName: "",
                type: 1,
                priece: 0,
                required: false,
                items: [],
                storeId: this.$route.params.storeId
            };
            this.optionDialog.visible = true;
        }

    }
}
</script>

<style scoped lang="less">
.root {
    width: 1100px;
}

.image-container {
    width: 150px;
    height: 150px;
    display: inline-block;
}

.category-list {
    border-top: 1px solid #eee;

    .category-item {
        background: white;
        border-bottom: 1px solid #eee;
    }

    .item-list {
        margin-left: 50px;
        margin-bottom: 10px;

        .item {
            background: white;
            border-bottom: 1px solid #eee;
        }
    }
}

.option-container {
    margin-top: 20px;
    border: 1px solid #eee;
    padding: 10px;

    .option-list {
        .option-item {
            border-bottom: 1px solid #eee;

            &.deleted {
                opacity: 0.5;
                text-decoration: line-through;

            }
        }
    }
}

.option-list {
    .option-item {
        &.deleted {
            opacity: 0.5;
            text-decoration: line-through;
        }
    }
}
</style>